import React, { Component } from 'react';
import {
  Grid,
  Paper,
  Tabs,
  Tab,
  Table,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import {
  Box,
  Button,
  Chip,
  Divider,
  Card,
  styled,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import config from '../../constants/index.jsx';
import Auth from 'app/helpers/authorization';
import Postie from 'app/helpers/postie';
import displayer from 'app/helpers/displayer';
import HeaderDetails from './components/Header.jsx';
import FooterBox from './components/Footer.jsx';
import ModularListRow from './components/ModularListRow.jsx';
import UnitSummary from './components/UnitSummay.jsx';
import ReactEcharts from 'echarts-for-react';
import RowComponent from './components/RowComponent.jsx';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DoughnutChart from '../dashboard/shared/Doughnut.jsx';
import AddServiceInBoq from './components/AddServiceInBoq.jsx';

const Title = styled('span')(() => ({
  fontSize: '1rem',
  fontWeight: '500',
  marginRight: '.5rem',
  textTransform: 'capitalize'
}));

const SubTitle = styled('span')(({ theme }) => ({
  fontSize: '0.875rem',
  color: theme.palette.text.secondary
}));

const legendStyle = {
  show: true,
  itemGap: 20,
  icon: 'circle',
  bottom: 0,
  textStyle: {
    color: '#333333',
    fontSize: 13,
    fontFamily: 'roboto'
  }
};
const tooltip = {
  show: false,
  trigger: 'item',
  formatter: '{a} <br/>{b}: {c} ({d}%)'
};
const xaxis = [
  {
    axisLine: {
      show: false
    },
    splitLine: {
      show: false
    }
  }
];
const yaxis = [
  {
    axisLine: {
      show: false
    },
    splitLine: {
      show: false
    }
  }
];

const DisplayEstimate = (props) => (
  <Paper style={{ padding: '8px', backgroundColor: '#FAF3F0' }} elevation={7}>
    <h3 style={{ textAlign: 'center' }}>{props.title}</h3>
    <Divider style={{ marginBottom: '3px' }} />

    <Grid container spacing={2}>
      <Grid item md="6" xs="6" sm="6">
        Net Cost:
      </Grid>
      <Grid item md="6" xs="6" sm="6" style={{ textAlign: 'right' }}>
        &#8377; {props.net_cost}
      </Grid>
      <Grid item md="6" xs="6" sm="6">
        Design consultation Cost:
      </Grid>
      <Grid item md="6" xs="6" sm="6" style={{ textAlign: 'right' }}>
        &#8377; {props.design_fee}
      </Grid>
      {props.discount > 0 && (
        <Grid item md="6" xs="6" sm="6">
          Discount{' '}
          {props.discount_type.toLowerCase() === 'percent'
            ? `(${props.discount_percent})`
            : `(Fixed)`}
        </Grid>
      )}
      {props.discount > 0 && (
        <Grid item md="6" xs="6" sm="6" style={{ textAlign: 'right' }}>
          &#8377; {props.discount}
        </Grid>
      )}
    </Grid>
    <Divider style={{ margin: '3px 0' }} />
    <Grid container spacing={2}>
      <Grid item md="6" xs="6" sm="6">
        <h4>Total:</h4>
        {props.total && (
          <Button size="small" variant="outlined" onClick={() => props.setOpenPiePopup()}>
            View Distribution
          </Button>
        )}
      </Grid>

      <Grid item md="6" xs="6" sm="6" style={{ textAlign: 'right' }}>
        <h4>&#8377; {props.total}</h4>
      </Grid>
    </Grid>
  </Paper>
);

const AccordionRoot = styled(Box)(({ theme }) => ({
  width: '100%',
  marginBottom: '10px',
  '& .heading': {
    fontSize: theme.typography.pxToRem(15),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(12)
    }
  },
  '& .secondaryHeading': {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(15),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(12)
    }
  },
  '& .icon': {
    width: 20,
    height: 20,
    verticalAlign: 'bottom'
  },
  '& .details': { alignItems: 'center' },
  '& .column': { flexBasis: '33.33%' },
  '& .helper': {
    padding: theme.spacing(1, 2),
    borderLeft: `2px solid ${theme.palette.divider}`
  },
  '& .link': {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    '&:hover': { textDecoration: 'underline' }
  }
}));

class CombineBoq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      projectData: '',
      estimateId: '',
      fitoutsData: '',
      modularData: '',
      ProductData: '',
      furnitureData: '',
      fitoutId: '',
      modularId: '',
      productId: '',
      furnitureId: '',
      options: {},
      total: {},
      estimates: {
        sections: []
      },
      estimateData: [],
      selectedModularTab: 0,
      setOpenPiePopup: false,
      chartData: [],
      unCheckedData: [],
      filter: '',
      scopeFilter: {
        name: '',
        value: ''
      },
      FitoutCatagories: '',
      ModularCatagories: '',
      FurnitureCatagories: '',
      setEditMode: false,
      isClient: true,
      openAddServicePopup: { show: false, estimateData: {} },
      setAddServicePopup: false
    };
  }

  componentDidMount = async () => {
    const queryParams = displayer.getUrlParams('/combine/estimates/', false);
    console.log('queryParams', queryParams);
    if (queryParams.length === 2) {
      console.log('token', queryParams[1]);
      if (queryParams[1] !== null) {
        localStorage.setItem(config.Token_name, queryParams[1]);
      }
    }
    this.getProjectDetails(queryParams[0]);
    this.getWorkCategories();
  };
  callBackFunction = () => {
    const queryParams = displayer.getUrlParams('/combine/estimates/');
    //console.log(queryParams);
    this.getProjectDetails(queryParams[0]);
  };

  getProjectDetails = async (id) => {
    let userLogged = await Auth.isUserLoggedIn();
    let url = config.BASE_API_URL + 'project/' + id + '/' + Auth.urlTokenizer();
    let jsonData = await Postie.fetchUrl(url);
    console.log('sata', jsonData);
    let loggedInUserRole = Auth.getUserProjectRole(jsonData.colabrators);
    //console.log('loggedinUser', Auth.getLoggedInUserData('id'), loggedInUserRole);
    this.setState(
      {
        ...this.state,
        projectData: jsonData,
        fitoutId: jsonData.fitout_boq?.id,
        modularId: jsonData.modular_boq?.id,
        productId: jsonData.product_boq?.id,
        furnitureId:
          jsonData?.furniture_boq && jsonData.furniture_boq.id ? jsonData.furniture_boq?.id : null,
        dataLoaded: true, // Set dataLoaded to true when data is loaded
        isClient: !(userLogged && loggedInUserRole.role !== 'client')
      },
      async function () {
        console.log('Project Details', this.state.projectData);
        let apicalls = [];
        if (this.state.fitoutId) {
          apicalls.push(await this.getFitoutEstimates(this.state.fitoutId));
        }

        if (this.state.modularId) {
          apicalls.push(await this.getModularEstimates(this.state.modularId));
        }
        if (this.state.productId) {
          apicalls.push(await this.getProductEstimates(this.state.productId));
        }
        if (this.state.furnitureId) {
          apicalls.push(await this.getFurnitureEstimates(this.state.furnitureId));
        }
        let result = Promise.all(apicalls);
      }
    );
  };
  getFitoutEstimates = async (id) => {
    let url = config.BASE_API_URL + 'estimate/' + id;
    let jsonData = await Postie.fetchUrl(url);
    let sectionData = this.state.estimates.sections;
    let total = {};
    jsonData.sections.forEach((section) => {
      if (section.services.length > 0) {
        console.log('sections', section);
        let services = section.services;
        // delete section['services'];

        if (!sectionData.hasOwnProperty(section.project_display_name)) {
          sectionData[section.project_display_name] = [];
          sectionData[section.project_display_name]['area'] = section;
        }
        sectionData[section.project_display_name]['fitout_services'] = services;
        if (!total.hasOwnProperty(section.project_display_name)) {
          total[section.project_display_name] = {
            fitouts: 0,
            modulars: 0,
            furniture: 0,
            product: 0
          };
        }
        services.forEach((service) => {
          total[section.project_display_name]['fitouts'] =
            parseFloat(total[section.project_display_name]['fitouts']) + parseFloat(service.rate);
        });
      }
    });
    //console.log('sata', sectionData);
    this.setState(
      {
        fitoutsData: jsonData,
        estimateId: jsonData.id,
        estimates: { ...this.state.estimates, sections: sectionData },
        total: total,
        dataLoaded: true // Set dataLoaded to true when data is loaded
      },
      function () {
        console.log('getFitoutEstimates Data', this.state.estimates);
        this.generateChartOption();
      }
    );
    return jsonData;
  };
  getModularEstimates = async (id) => {
    let url = config.BASE_API_URL + 'estimate/' + id;
    let jsonData = await Postie.fetchUrl(url);
    let sectionData = this.state.estimates.sections;
    let total = this.state.total;
    jsonData.sections.forEach((section) => {
      if (section.services.length > 0) {
        console.log('sections', section);
        let services = section.services;
        // delete section['services'];

        if (!sectionData.hasOwnProperty(section.project_display_name)) {
          sectionData[section.project_display_name] = [];
          sectionData[section.project_display_name]['area'] = section;
        }
        sectionData[section.project_display_name]['modular_services'] = services;
        if (!total.hasOwnProperty(section.project_display_name)) {
          total[section.project_display_name] = {
            fitouts: 0,
            modulars: 0,
            furniture: 0,
            product: 0
          };
        }
        services.forEach((service) => {
          total[section.project_display_name]['modulars'] =
            parseFloat(total[section.project_display_name]['modulars']) + parseFloat(service.rate);
        });
      }
    });
    //console.log('sata', sectionData);
    this.setState(
      {
        ...this.state,
        modularData: jsonData,
        estimateId: jsonData.id,
        estimates: { ...this.state.estimates, sections: sectionData },
        total: total,
        dataLoaded: true // Set dataLoaded to true when data is loaded
      },
      function () {
        console.log('getFitoutEstimates Data', this.state.estimates);
        this.generateChartOption();
      }
    );
    return jsonData;
  };
  /** getModularEstimatesData Not in use unit sketchup plugin is used */
  getModularEstimatesData = async (id) => {
    let url = config.BASE_API_URL + 'modularestimate/get/' + id;
    let jsonData = await Postie.fetchUrl(url);

    let sectionData = this.state.estimates.sections;
    let total = this.state.total;
    jsonData.modules.forEach((section) => {
      let services = [];
      if (section.kitchen.modules.length > 0) {
        console.log('kitchen', section);
        services['kitchen'] = section.kitchen.modules;
      }
      if (section.wardrobe.modules.length > 0) {
        console.log('wardrobe', section);
        services['wardrobe'] = section.wardrobe.modules;
      }
      if (section.storage.modules.length > 0) {
        console.log('storage', section);
        services['storage'] = section.storage.modules;
      }
      //delete section['services'];

      if (!sectionData.hasOwnProperty(section.project_display_name)) {
        sectionData[section.project_display_name] = [];
      }
      sectionData[section.project_display_name]['modular_services'] = services;
      if (!total.hasOwnProperty(section.project_display_name)) {
        total[section.project_display_name] = {
          fitouts: 0,
          modulars: 0,
          furniture: 0,
          product: 0
        };
      }
      services.forEach((service) => {
        total[section.project_display_name]['fitouts'] =
          parseFloat(total[section.project_display_name]['fitouts']) + parseFloat(service.rate);
      });
    });
    //console.log('sata', jsonData);
    this.setState(
      {
        ...this.state,
        modularData: jsonData,
        estimates: { ...this.state.estimates, sections: sectionData },
        total: total,
        dataLoaded: true // Set dataLoaded to true when data is loaded
      },
      function () {
        console.log('getModularEstimatesData Data', this.state.estimates);
        this.generateChartOption();
      }
    );
    return jsonData;
  };
  getProductEstimates = async (id) => {
    let url = config.BASE_API_URL + 'estimate/' + id;
    let jsonData = await Postie.fetchUrl(url);
    let sectionData = this.state.estimates.sections;
    let total = this.state.total;
    jsonData.sections.forEach((section) => {
      if (section.services.length > 0) {
        console.log('sections', section);
        let services = section.services;
        //delete section['services'];

        if (!sectionData.hasOwnProperty(section.project_display_name)) {
          sectionData[section.project_display_name] = [];
          sectionData[section.project_display_name]['area'] = section;
        }
        sectionData[section.project_display_name]['product_services'] = services;
        if (!total.hasOwnProperty(section.project_display_name)) {
          total[section.project_display_name] = {
            fitouts: 0,
            modulars: 0,
            furniture: 0,
            product: 0
          };
        }
        services.forEach((service) => {
          total[section.project_display_name]['product'] =
            parseFloat(total[section.project_display_name]['product']) + parseFloat(service.rate);
        });
      }
    });
    //console.log('sata', jsonData);
    this.setState(
      {
        ...this.state,
        ProductData: jsonData,
        estimateId: jsonData.id,
        dataLoaded: true, // Set dataLoaded to true when data is loaded
        total: total,
        estimates: { ...this.state.estimates, sections: sectionData }
      },
      function () {
        console.log('getProductEstimates Data', this.state.estimates);
        this.generateChartOption();
      }
    );
    return jsonData;
  };
  getFurnitureEstimates = async (id) => {
    let url = config.BASE_API_URL + 'estimate/' + id;
    let jsonData = await Postie.fetchUrl(url);
    let sectionData = this.state.estimates.sections;
    let total = this.state.total;
    jsonData.sections.forEach((section) => {
      if (section.services.length > 0) {
        console.log('sections', section);
        let services = section.services;
        //delete section['services'];

        if (!sectionData.hasOwnProperty(section.project_display_name)) {
          sectionData[section.project_display_name] = [];
          sectionData[section.project_display_name]['area'] = section;
        }
        sectionData[section.project_display_name]['furniture_services'] = services;
        if (!total.hasOwnProperty(section.project_display_name)) {
          total[section.project_display_name] = {
            fitouts: 0,
            modulars: 0,
            furniture: 0,
            product: 0
          };
        }
        services.forEach((service) => {
          total[section.project_display_name]['furniture'] =
            parseFloat(total[section.project_display_name]['furniture']) + parseFloat(service.rate);
        });
      }
    });
    //console.log('sata', jsonData);
    this.setState(
      {
        ...this.state,
        furnitureData: jsonData,
        estimateId: jsonData.id,
        estimates: { ...this.state.estimates, sections: sectionData },
        total: total,
        dataLoaded: true // Set dataLoaded to true when data is loaded
      },
      function () {
        console.log('getFurnitureEstimates Data', this.state.estimates);
        this.generateChartOption();
      }
    );
    return jsonData;
  };
  handleTabChange = (event, newValue) => {
    this.setState({ selectedTab: newValue });
  };
  handleModularTabChange = (event, newValue) => {
    this.setState({ selectedModularTab: newValue });
  };

  generateChartOption = async () => {
    let total = 0;
    let fitoutbudget = 0;
    let modularbudget = 0;
    let productbudget = 0;
    let furniturebudget = 0;
    if (this.state.fitoutsData) {
      fitoutbudget = parseFloat(this.state.fitoutsData.budget);
      total = total + fitoutbudget;
      console.log('fitoutData', fitoutbudget, total);
    }
    if (this.state.modularData) {
      modularbudget = parseFloat(this.state.modularData.budget);
      total = total + modularbudget;
      console.log('modularbudget', modularbudget, total);
    }
    if (this.state.ProductData) {
      productbudget = parseFloat(this.state.ProductData.budget);
      total = total + productbudget;
      console.log('productbudget', productbudget, total);
    }
    if (this.state.furnitureData) {
      furniturebudget = parseFloat(this.state.furnitureData.budget);
      total = total + furniturebudget;
      console.log('furnitureData', furniturebudget, total);
    }

    let fitoutPercent = 100;
    let modularPercent = 0;
    let furniturePercent = 0;
    let productPercent = 0;
    if (total > 0) {
      fitoutPercent = ((fitoutbudget / total) * 100).toFixed(2);
      modularPercent = ((modularbudget / total) * 100).toFixed(2);
      productPercent = ((productbudget / total) * 100).toFixed(2);
      furniturePercent = ((furniturebudget / total) * 100).toFixed(2);
    }

    const option = {
      legend: legendStyle,
      tooltip: tooltip,
      xAxis: xaxis,
      yAxis: yaxis,
      color: ['red', 'green', 'orange', 'blue'],
      series: [
        {
          name: 'Costing Share',
          type: 'pie',
          radius: ['45%', '72.55%'],
          center: ['50%', '50%'],
          avoidLabelOverlap: false,
          hoverOffset: 5,
          stillShowZeroSum: false,
          label: {
            normal: {
              show: false,
              position: 'center', // shows the description data to center, turn off to show in right side
              textStyle: {
                color: '#333333',
                fontSize: 13,
                fontFamily: 'roboto'
              },
              formatter: '{a}'
            },
            emphasis: {
              show: true,
              textStyle: {
                fontSize: '14',
                fontWeight: 'normal'
                // color: "rgba(15, 21, 77, 1)"
              },
              formatter: '{b} \n{c} ({d}%)'
            }
          },
          labelLine: {
            normal: {
              show: false
            }
          },
          data: [
            {
              value: Math.ceil(fitoutPercent),
              name: 'Fitout Estimate'
            },
            {
              value: Math.ceil(modularPercent),
              name: 'Modular Estimate'
            },
            { value: Math.ceil(productPercent), name: 'Product Estimate' },
            { value: Math.ceil(furniturePercent), name: 'Furniture Estimate' }
          ],
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    };
    console.log('generateOption', option, total);
    this.setState({ ...this.state, options: option });
  };

  getPieChartData = async (id) => {
    let url = config.BASE_API_URL + 'cms/estimate/graphicaldata/' + id;
    let jsonData = await Postie.fetchUrl(url);
    console.log('jsonData', jsonData);
    if (jsonData) {
      this.setState(
        {
          ...this.state,
          estimateData: jsonData
        },
        function () {
          console.log('estimateData', this.state.estimateData);
        }
      );
    }
  };

  setOpenPiePopup = async (estimateId) => {
    this.getPieChartData(estimateId);
    let data = await this.state.estimateData;
    if (data) {
      this.setState({ setOpenPiePopup: true });
    }
  };
  closeOpenPiePopup = () => {
    this.setState({ setOpenPiePopup: false });
  };

  updateDistribution = (record, value) => {
    console.log('value', value);
    if (value === false) {
      this.setState(
        (prevState) => ({
          unCheckedData: [...prevState.unCheckedData, record]
        }),
        () => {
          console.log('state updated record', this.state.unCheckedData);
        }
      );
    } else if (value === true) {
      this.setState(
        (prevState) => ({
          unCheckedData: prevState.unCheckedData.filter((data) => data !== record)
        }),
        () => {
          console.log('state updated record', this.state.unCheckedData);
        }
      );
    }
  };

  getChartData = () => {
    let estimateData = this.state.estimateData;
    let removedData = this.state.unCheckedData;
    let chartData = [];

    estimateData.forEach((record) => {
      if (!removedData.includes(record)) {
        chartData.push({
          name: record.name + '(' + record.UOM + ')',
          value: record.value
        });
      }
    });

    return chartData;
  };
  getWorkCategories = async () => {
    let url = config.BASE_API_URL + 'cms/estimate/workcategories/' + Auth.urlTokenizer();
    const json = await Postie.fetchUrl(url);
    // console.log('json',json);
    const fitouts = [];
    console.log('fitouts', fitouts);
    const modulars = [];
    const furniture = [];
    json.forEach((record) => {
      if (record.boq_type.trim().toLowerCase() === 'fitouts') {
        fitouts.push(record);
      }
      if (record.boq_type.trim().toLowerCase() === 'modular') {
        modulars.push(record);
      }
      if (record.boq_type.trim().toLowerCase() === 'furniture') {
        furniture.push(record);
      }
    });
    this.setState(
      (prevState) => ({
        FitoutCatagories: fitouts
      }),
      () => {
        console.log('FitoutCatagories', this.state.FitoutCatagories);
      }
    );
    this.setState(
      (prevState) => ({
        ModularCatagories: modulars
      }),
      () => {
        console.log('ModularCatagories', this.state.ModularCatagories);
      }
    );
    this.setState(
      (prevState) => ({
        FurnitureCatagories: furniture
      }),
      () => {
        console.log('FurnitureCatagories', this.state.FurnitureCatagories);
      }
    );
  };
  onAreaFilter = (e) => {
    const { name, value } = e.target;
    console.log('onAreaFilter', name, value);

    //this.setState({ filter: value });
    document
      .getElementById(value)
      .scrollIntoView({ block: 'start', behavior: 'smooth', top: '20%' });
  };
  onScopeFilter = (e) => {
    const { name, value } = e.target;
    console.log('onScopeFilter', name, value);
    this.setState(
      {
        scopeFilter: {
          name: name,
          value: value
        }
      },
      function () {
        console.log('scopeFilter', this.state.scopeFilter);
      }
    );
  };
  onEditBoq = async () => {
    const queryParams = displayer.getUrlParams('/combine/estimates/');
    let userLogged = await Auth.isUserLoggedIn();
    console.log('userLogged', userLogged, queryParams);
    let Username = Auth.getLoggedInUserData('member_name');
    let fitoutReason = `${Username} has initiated the change in Fitout BOQ (${
      this.state.fitoutsData.id
    }) on ${displayer.currentDate(0)}`;
    let modularReason = `${Username} has initiated the change in Modular BOQ (${
      this.state.modularData.id
    }) on ${displayer.currentDate(0)}`;
    let productReason = `${Username} has initiated the change in Product BOQ (${
      this.state.ProductData.id
    }) on ${displayer.currentDate(0)}`;
    let furnitureReason = `${Username} has initiated the change in Furniture BOQ (${
      this.state.furnitureData.id
    }) on ${displayer.currentDate(0)}`;

    let postDataArray = [];

    if (this.state.fitoutsData.id) {
      postDataArray.push({
        estimate_id: this.state.fitoutsData.id,
        project_id: queryParams[0],
        reason: fitoutReason,
        authToken: localStorage.getItem(config.Token_name)
      });
    }

    if (this.state.modularData.id) {
      postDataArray.push({
        estimate_id: this.state.modularData.id,
        project_id: queryParams[0],
        reason: modularReason,
        authToken: localStorage.getItem(config.Token_name)
      });
    }

    if (this.state.ProductData.id) {
      postDataArray.push({
        estimate_id: this.state.ProductData.id,
        project_id: queryParams[0],
        reason: productReason,
        authToken: localStorage.getItem(config.Token_name)
      });
    }

    if (this.state.furnitureData.id) {
      postDataArray.push({
        estimate_id: this.state.furnitureData.id,
        project_id: queryParams[0],
        reason: furnitureReason,
        authToken: localStorage.getItem(config.Token_name)
      });
    }

    let promises = postDataArray.map(async (postData) => {
      var servicesUrl = config.BASE_API_URL + 'estimate/change';
      console.log('postData', postData, servicesUrl);
      return Postie.sendAsynchrnousCall(postData, servicesUrl);
    });

    let responses = await Promise.all(promises);
    console.log('responses', responses);
    if (responses) {
      this.getProjectDetails(queryParams[0]);
      this.getWorkCategories();
      this.setState({
        setEditMode: true
      });
    }
  };
  onCloseEditBoq = () => {
    this.setState({
      setEditMode: false
    });
  };

  addServiceInBoq = (serviceEstimateData) => {
    this.setState(
      {
        openAddServicePopup: { show: true, estimateData: serviceEstimateData }
      },
      function () {
        console.log('fitout boq Id', this.state.fitoutsData);
        console.log('Modular boq Id', this.state.modularData.id);
        console.log('product boq Id', this.state.ProductData.id);
        console.log('furniture boq Id', this.state.furnitureData.id);
      }
    );
    this.closeAddService();
  };
  closeServicePopup = () => {
    this.setState({
      openAddServicePopup: { show: false, estimateData: {} }
    });
  };

  getViewImages = (views) => {
    let images = [];
    views?.forEach((detail) => {
      if (detail.site_picture) {
        images.push({ id: '', image: detail.site_picture });
      }
      if (detail.image) {
        images.push({ id: '', image: detail.image });
      }
      if (detail.images.length > 0) {
        detail.images.forEach((image) => {
          images.push(image);
        });
      }
    });
    console.log('getViewImages', images);
    // this.setState({ ...this.state, viewImages: images }, function () {
    //   console.log('viewImages Loaded', this.state.viewImages);
    // });
    return images;
  };

  handleOpenAddService = () => {
    this.setState({ setAddServicePopup: true });
  };
  closeAddService = () => {
    this.setState({ setAddServicePopup: false });
  };

  render = () => {
    const {
      projectData,
      fitoutsData,
      modularData,
      dataLoaded,
      ProductData,
      furnitureData,
      estimates,
      total
    } = this.state;
    const sections = estimates.sections;
    console.log('openAddServicePopup', this.state.openAddServicePopup);
    console.log('serviceEstimateData', this.state.serviceEstimateData);

    // console.log('fitoutsData', fitoutsData);
    // console.log('modularData', modularData);
    // console.log('ProductData', ProductData);
    // console.log('furnitureData', furnitureData);
    // Only render if data is loaded

    if (!dataLoaded) {
      return (
        <Grid
          container
          md={12}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh'
          }}
        >
          <h3>Loading data...</h3>
        </Grid>
      );
    }

    return (
      <Grid container style={{ margin: '10px' }}>
        <HeaderDetails
          boq_name={'Combine Boq'}
          data={fitoutsData.project}
          budget={projectData.project_budget}
          totalCost={
            parseFloat(fitoutsData ? fitoutsData.budget : 0.0) +
            parseFloat(modularData ? modularData.budget : 0.0) +
            parseFloat(ProductData ? ProductData.budget : 0.0) +
            parseFloat(furnitureData ? furnitureData.budget : 0.0)
          }
          fitoutcost={parseFloat(fitoutsData ? fitoutsData.budget : 0.0)}
          modularCost={parseFloat(modularData ? modularData.budget : 0.0)}
          productCost={parseFloat(ProductData ? ProductData.budget : 0.0)}
          furnitureCost={parseFloat(furnitureData ? furnitureData.budget : 0.0)}
        />
        <Grid container>
          <Grid item md="12" xs="12" sm="12" style={{ padding: ' 0 40px', marginBottom: '20px' }}>
            <Grid container spacing={2}>
              <Grid item xs="12" sm="12" md="3">
                <DisplayEstimate
                  title={`Fitout Estimate (#${fitoutsData?.id ? fitoutsData?.id : ''})`}
                  total={fitoutsData ? fitoutsData.budget : 0.0}
                  net_cost={fitoutsData ? fitoutsData.net_cost : 0.0}
                  discount={fitoutsData ? fitoutsData.discount : 0.0}
                  design_fee={fitoutsData ? fitoutsData.consultation_cost : 0.0}
                  discount_percent={fitoutsData?.discount_percent}
                  discount_type={fitoutsData?.discount_type}
                  setOpenPiePopup={() => this.setOpenPiePopup(fitoutsData.id)}
                />
              </Grid>
              <Grid item xs="12" sm="12" md="3">
                <DisplayEstimate
                  title={`Modular Estimate (#${modularData?.id ? modularData?.id : ''})`}
                  total={modularData ? modularData.budget : 0.0}
                  net_cost={modularData ? modularData.net_cost : 0.0}
                  discount={modularData ? modularData.discount : 0.0}
                  design_fee={modularData ? modularData.consultation_cost : 0.0}
                  discount_percent={modularData?.discount_percent}
                  discount_type={modularData?.discount_type}
                  setOpenPiePopup={() => this.setOpenPiePopup(modularData.id)}
                />
              </Grid>
              <Grid item xs="12" sm="12" md="3">
                <DisplayEstimate
                  title={`Furniture Estimate (#${furnitureData?.id ? furnitureData?.id : ''})`}
                  total={furnitureData ? furnitureData.budget : 0.0}
                  net_cost={furnitureData ? furnitureData.net_cost : 0.0}
                  discount={furnitureData ? furnitureData.discount : 0.0}
                  design_fee={furnitureData ? furnitureData.consultation_cost : 0.0}
                  discount_percent={furnitureData?.discount_percent}
                  discount_type={furnitureData?.discount_type}
                  setOpenPiePopup={() => this.setOpenPiePopup(furnitureData.id)}
                />
              </Grid>
              <Grid item xs="12" sm="12" md="3">
                <DisplayEstimate
                  title={`Product Estimate (#${ProductData?.id ? ProductData?.id : ''})`}
                  total={ProductData ? ProductData.budget : 0.0}
                  net_cost={ProductData ? ProductData.net_cost : 0.0}
                  discount={ProductData ? ProductData.discount : 0.0}
                  design_fee={ProductData ? ProductData.consultation_cost : 0.0}
                  discount_percent={ProductData?.discount_percent}
                  discount_type={ProductData?.discount_type}
                  setOpenPiePopup={() => this.setOpenPiePopup(ProductData.id)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            md={12}
            style={{
              position: '-webkit-sticky',
              position: 'sticky',
              top: '0',
              padding: '10px 40px 0px 55px',
              fontSize: '20px',
              zIndex: '999'
            }}
          >
            <Grid container spacing={2} style={{ background: '#dddddd', borderRounded: '5px' }}>
              <Grid container spacing={2} style={{ margin: '10px 0 0 0' }}>
                <Grid item md={12} xs={12}>
                  <h5 style={{ margin: '5px 10px' }}>Filters</h5>
                </Grid>
                <Grid item md={2} xs={6}>
                  <div>
                    <FormControl variant="outlined" style={{ width: '90%' }}>
                      <InputLabel>Go to Area</InputLabel>
                      <Select
                        label="Choose Area"
                        name="area"
                        onChange={(e) => this.onAreaFilter(e)}
                        defaultValue=""
                      >
                        {sections &&
                          Object.keys(sections).map((area, index) => (
                            <MenuItem key={index} value={sections[area]['area']['id']}>
                              {area} (Floor {sections[area]['area']['floor']})
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                </Grid>
                {this.state.fitoutId && (
                  <Grid item md={2} xs={6}>
                    <div>
                      <FormControl variant="outlined" style={{ width: '90%' }}>
                        <InputLabel>Choose fitout Scope</InputLabel>
                        <Select
                          label="Choose fitout Scope"
                          onChange={(e) => this.onScopeFilter(e, 'fitouts')}
                          name="scopefilter"
                          defaultValue=""
                        >
                          <MenuItem value="">
                            <em>All</em>
                          </MenuItem>

                          {this.state.FitoutCatagories &&
                            this.state.FitoutCatagories.map((record, index) => (
                              <MenuItem key={index} value={record.sow}>
                                {record.sow}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                )}
                {this.state.modularId && (
                  <Grid item md={2} xs={6}>
                    <div>
                      <FormControl variant="outlined" style={{ width: '90%' }}>
                        <InputLabel>Choose modular Scope</InputLabel>
                        <Select
                          label="Choose modular Scope"
                          onChange={(e) => this.onScopeFilter(e, 'modular')}
                          name="modular"
                          defaultValue=""
                        >
                          <MenuItem value="">
                            <em>All</em>
                          </MenuItem>

                          {this.state.ModularCatagories &&
                            this.state.ModularCatagories.map((record, index) => (
                              <MenuItem key={index} value={record.sow}>
                                {record.sow}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                )}
                {this.state.furnitureId && (
                  <Grid item md={2} xs={6}>
                    <div>
                      <FormControl variant="outlined" style={{ width: '90%' }}>
                        <InputLabel>Choose furniture Scope</InputLabel>
                        <Select
                          label="Choose furniture Scope"
                          onChange={(e) => this.onScopeFilter(e, 'furniture')}
                          name="furniture"
                          defaultValue=""
                        >
                          <MenuItem value="">
                            <em>All</em>
                          </MenuItem>

                          {this.state.FurnitureCatagories &&
                            this.state.FurnitureCatagories.map((record, index) => (
                              <MenuItem key={index} value={record.sow}>
                                {record.sow}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                )}
                {!this.state.isClient && (
                  <Grid item md={2} xs={6}>
                    {this.state.setEditMode && (
                      <Button
                        onClick={() => this.handleOpenAddService()}
                        color="secondary"
                        variant="contained"
                        size="large"
                      >
                        Add Service
                      </Button>
                    )}
                  </Grid>
                )}
                {!this.state.isClient && (
                  <Grid item md={2} xs={6}>
                    {this.state.setEditMode ? (
                      <Button
                        onClick={() => this.onCloseEditBoq()}
                        color="secondary"
                        variant="contained"
                        size="large"
                      >
                        Close Edit
                      </Button>
                    ) : (
                      <Button
                        onClick={() => this.onEditBoq()}
                        color="secondary"
                        variant="contained"
                        size="large"
                      >
                        Edit
                      </Button>
                    )}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12} style={{ padding: ' 0 40px' }}>
            <Box style={{ margin: '15px' }}>Attachment “A” – Pricing Detail and Breakout.</Box>
          </Grid>
          <Grid item md={12} style={{ padding: ' 0 40px' }}>
            {sections &&
              Object.keys(sections).map((area, index) => (
                <Grid
                  id={sections[area]['area']['id']}
                  className={area}
                  conatiner
                  key={`${sections[area]['area'].services[0].estimate_id}`}
                  style={{
                    padding: '10px',
                    margin: '10px 0',
                    backgroundColor: '#E3E1D9'
                  }}
                >
                  {this.state.setEditMode && (
                    <Grid item md={6} xs={12} sx={{ margin: '5px', float: 'right' }}>
                      <Button
                        //onClick={() => this.onEditBoq()}
                        color="secondary"
                        variant="contained"
                        size="large"
                      >
                        Add Product
                      </Button>
                    </Grid>
                  )}
                  {this.state.setEditMode && (
                    <Grid item md={6} xs={12} sx={{ margin: '5px', float: 'right' }}>
                      <Button
                        onClick={() => this.addServiceInBoq(sections[area]['area'])}
                        color="secondary"
                        variant="contained"
                        size="large"
                      >
                        Add Service
                      </Button>
                    </Grid>
                  )}

                  <Grid item md="6" xs={6}>
                    <h4 key={total[area]} style={{ float: 'right' }}>
                      Area Total: &nbsp; &#8377;
                      {parseFloat(total[area]['fitouts']) +
                        parseFloat(total[area]['modulars']) +
                        parseFloat(total[area]['furniture']) +
                        parseFloat(total[area]['product'])}
                    </h4>
                    <h3>
                      {area} (Floor {sections[area]['area']['floor']})
                    </h3>
                  </Grid>
                  <Grid item md="12">
                    {sections[area] && sections[area].fitout_services && (
                      <AccordionRoot>
                        <Accordion defaultExpanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1c-content"
                            id="panel1c-header"
                          >
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={12} md={10}>
                                <h3 style={{ float: 'left' }}>Fitout services</h3>
                              </Grid>
                              <Grid item xs={12} sm={12} md={2}>
                                <strong
                                  key={total[area]['fitouts']}
                                  style={{ float: 'right', marginTop: '10px' }}
                                >
                                  Total :&#8377;{parseFloat(total[area]['fitouts'])}
                                </strong>
                              </Grid>
                            </Grid>
                            {console.log('Fitout services', sections[area].fitout_services)}
                          </AccordionSummary>
                          <Divider />

                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12}>
                              <AccordionDetails className="details">
                                {console.log('images', sections[area])}
                                {sections[area].fitout_services.map((cfg, index) => (
                                  <RowComponent
                                    key={index}
                                    data={cfg}
                                    area={area.project_display_name}
                                    estimateId={this.state.estimateId}
                                    is_editable={false}
                                    scopeFilterData={this.state.scopeFilter}
                                    setEditMode={this.state.setEditMode}
                                    fitoutCategories={this.state.FitoutCatagories}
                                    modularCategories={this.state.ModularCatagories}
                                    furnitureCategories={this.state.FurnitureCatagories}
                                    projectId={projectData.id}
                                    areaImages={this.getViewImages(sections[area]['area']['views'])}
                                    serviceEstimateData={sections[area]['area']}
                                    callBack={() => this.callBackFunction()}
                                  />
                                ))}
                              </AccordionDetails>
                            </Grid>
                          </Grid>
                        </Accordion>
                      </AccordionRoot>
                    )}
                    {sections[area] && sections[area].modular_services && (
                      <AccordionRoot>
                        <Accordion defaultExpanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1c-content"
                            id="panel1c-header"
                          >
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={12} md={10}>
                                <h3 style={{ float: 'left' }}>Modular services</h3>
                              </Grid>
                              <Grid item xs={12} sm={12} md={2}>
                                <strong
                                  key={total[area]['modulars']}
                                  style={{ float: 'right', marginTop: '10px' }}
                                >
                                  Total :&#8377;{parseFloat(total[area]['modulars'])}
                                </strong>
                              </Grid>
                            </Grid>
                          </AccordionSummary>
                          <Divider />

                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12}>
                              <AccordionDetails className="details">
                                {sections[area].modular_services.map((cfg, index) => (
                                  <RowComponent
                                    key={index}
                                    data={cfg}
                                    area={area.project_display_name}
                                    estimateId={this.state.estimateId}
                                    is_editable={false}
                                    scopeFilterData={this.state.scopeFilter}
                                    setEditMode={this.state.setEditMode}
                                    fitoutCategories={this.state.FitoutCatagories}
                                    modularCategories={this.state.ModularCatagories}
                                    furnitureCategories={this.state.FurnitureCatagories}
                                    areaImages={this.getViewImages(sections[area]['area']['views'])}
                                    projectId={projectData.id}
                                    serviceEstimateData={sections[area]['area']}
                                    callBack={() => this.callBackFunction()}
                                  />
                                ))}
                              </AccordionDetails>
                            </Grid>
                          </Grid>
                        </Accordion>
                      </AccordionRoot>
                    )}
                    {sections[area] && sections[area].furniture_services && (
                      <AccordionRoot>
                        <Accordion defaultExpanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1c-content"
                            id="panel1c-header"
                          >
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={12} md={10}>
                                <h3 style={{ float: 'left' }}>Furnitures</h3>
                              </Grid>
                              <Grid item xs={12} sm={12} md={2}>
                                <strong
                                  key={total[area]['furniture']}
                                  style={{ float: 'right', marginTop: '10px' }}
                                >
                                  Total :&#8377;{parseFloat(total[area]['furniture'])}
                                </strong>
                              </Grid>
                            </Grid>
                          </AccordionSummary>
                          <Divider />

                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12}>
                              <AccordionDetails className="details">
                                {sections[area].furniture_services.map((cfg, index) => (
                                  <RowComponent
                                    key={index}
                                    data={cfg}
                                    area={area.project_display_name}
                                    estimateId={this.state.estimateId}
                                    is_editable={this.state.is_editable}
                                    scopeFilterData={this.state.scopeFilter}
                                    setEditMode={this.state.setEditMode}
                                    fitoutCategories={this.state.FitoutCatagories}
                                    modularCategories={this.state.ModularCatagories}
                                    furnitureCategories={this.state.FurnitureCatagories}
                                    areaImages={this.getViewImages(sections[area]['area']['views'])}
                                    projectId={projectData.id}
                                    serviceEstimateData={sections[area]['area']}
                                    callBack={() => this.callBackFunction()}
                                  />
                                ))}
                              </AccordionDetails>
                            </Grid>
                          </Grid>
                        </Accordion>
                      </AccordionRoot>
                    )}
                    {sections[area] && sections[area].product_services && (
                      <AccordionRoot>
                        <Accordion defaultExpanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1c-content"
                            id="panel1c-header"
                          >
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={12} md={10}>
                                <h3 style={{ float: 'left' }}>Products</h3>
                              </Grid>
                              <Grid item xs={12} sm={12} md={2}>
                                <strong
                                  key={total[area]['furniture']}
                                  style={{ float: 'right', marginTop: '10px' }}
                                >
                                  Total :&#8377;{parseFloat(total[area]['product'])}
                                </strong>
                              </Grid>
                            </Grid>
                          </AccordionSummary>
                          <Divider />

                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12}>
                              <AccordionDetails className="details">
                                {sections[area].product_services.map((cfg, index) => (
                                  <RowComponent
                                    key={index}
                                    data={cfg}
                                    area={area.project_display_name}
                                    estimateId={this.state.estimateId}
                                    is_editable={this.state.is_editable}
                                    scopeFilterData={this.state.scopeFilter}
                                    setEditMode={this.state.setEditMode}
                                    fitoutCategories={this.state.FitoutCatagories}
                                    modularCategories={this.state.ModularCatagories}
                                    furnitureCategories={this.state.FurnitureCatagories}
                                    areaImages={this.getViewImages(sections[area]['area']['views'])}
                                    projectId={projectData.id}
                                    serviceEstimateData={sections[area]['area']}
                                    callBack={() => this.callBackFunction()}
                                  />
                                ))}
                              </AccordionDetails>
                            </Grid>
                          </Grid>
                        </Accordion>
                      </AccordionRoot>
                    )}
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </Grid>
        {this.state.openAddServicePopup && this.state.FitoutCatagories && (
          <AddServiceInBoq
            setOpenPopup={this.state.openAddServicePopup.show}
            closePopup={() => this.closeServicePopup()}
            fitoutCategories={this.state.FitoutCatagories}
            modularCategories={this.state.ModularCatagories}
            furnitureCategories={this.state.FurnitureCatagories}
            fitoutId={this.state.fitoutsData.id}
            modularId={this.state.modularData.id}
            furnitureId={this.state.furnitureData.id}
            projectId={projectData.id}
            serviceEstimateData={this.state.openAddServicePopup.estimateData}
            callBack={() => this.callBackFunction()}
            serviceData=""
          />
        )}
        <FooterBox
          terms={fitoutsData.project?.organization?.terms}
          payment_terms={fitoutsData.project?.organization?.payment_terms}
          organization={fitoutsData.project?.organization}
        />
        <Dialog
          open={this.state.setOpenPiePopup}
          onClose={this.closeAddLeadDialog}
          fullWidth
          maxWidth="xl"
        >
          <DialogTitle>Categories Distribution <Button onClick={this.closeOpenPiePopup} color="secondary" sx={{float:'right'}}>
              Close
            </Button></DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item md="12" xs="12" sm="12">
                <DoughnutChart
                  height={'450px'}
                  name="Cost Distribution"
                  actualData={this.getChartData()}
                  displayTotal={true}
                />
              </Grid>
              {this.state.estimateData &&
                this.state.estimateData.map(
                  (record, index) =>
                    record.name !== 'Total' && (
                      <Grid container spacing={2} style={{ padding: '3px' }} key={index}>
                        <Grid item xs="6" sm="6" md="2">
                          <strong>Include</strong>
                          <div>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  key={index}
                                  name={`service distribution`}
                                  onChange={(e) =>
                                    this.updateDistribution(record, e.target.checked)
                                  }
                                  color="primary"
                                  defaultChecked
                                />
                              }
                            />
                          </div>
                        </Grid>
                        <Grid item xs="6" sm="6" md="2">
                          <strong>Name</strong>
                          <div>
                            <span>{record.name}</span>
                          </div>
                        </Grid>
                        <Grid item xs="6" sm="6" md="2">
                          <strong>UOM</strong>
                          <div>
                            <span>{record.UOM}</span>
                          </div>
                        </Grid>
                        <Grid item xs="6" sm="6" md="2">
                          <strong>Cost</strong>
                          <div>
                            <span>
                              &#8377;
                              {record?.value.length > 1
                                ? parseFloat(record.value).toFixed(2)
                                : record?.value}
                            </span>
                          </div>
                        </Grid>
                        <Grid item xs="6" sm="6" md="2">
                          <strong>Average Rate</strong>
                          <div>
                            <span>
                              &#8377;
                              {record?.AVG_RATE.length > 1
                                ? parseFloat(record.AVG_RATE).toFixed(2)
                                : record?.AVG_RATE}
                            </span>
                          </div>
                        </Grid>
                      </Grid>
                    )
                )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeOpenPiePopup} color="secondary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.setAddServicePopup}
          onClose={this.closeAddService}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>
            Add Service
            <Button onClick={this.closeAddService} sx={{ float: 'right' }} color="secondary">
              Close
            </Button>
          </DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item md={4}>
                <Select
                  style={{ width: '100%' }}
                  label="Choose Area"
                  name="area"
                  onChange={(e) => this.addServiceInBoq(e.target.value)}
                  defaultValue=""
                >
                  <MenuItem>Select Area to add Service</MenuItem>
                  {projectData &&
                    projectData.areas.map((area) => (
                      <MenuItem value={area}>{area.project_display_name}</MenuItem>
                    ))}
                </Select>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeAddService} color="secondary">
              Close
            </Button>
            {/* <Button onClick={this.submitClientDetails} color="primary">
              Add Client
            </Button> */}
          </DialogActions>
        </Dialog>
      </Grid>
    );
  };
}

export default CombineBoq;
